/* App.css */

html.light,
body.light {
  background-color: #f0f0f0;
}

html.dark,
body.dark {
  background-color: #333;
  color: #f0f0f0;
}


html {
  scroll-behavior: smooth;
  transition: background-color 2s ease;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
}

.light {
  background-color: #f0f0f0;
  color: #333;
}

body.light {
  background-color: #f0f0f0;
  color: #333;
}

/* Dark mode styles */

.dark {
  background-color: #333;
  color: #f0f0f0;
}

.dark body {
  background-color: #333;
  color: #f0f0f0;
}

body.dark {
  background-color: #333;
  color: #f0f0f0;
}

.dark .name {
  color: #f0f0f0;
}

.dark .nav-link {
  color: #f0f0f0;
}

/* Standard website */

.App {
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  justify-items: center;
}

.main-content {
  max-width: 500px;
  margin: 0 auto;
  padding: 1rem;

  text-align: center;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
}

.name-container {
  display: flex;
  justify-content: center;
}

.name {
  font-size: 3.5rem;
  font-weight: bold;
  margin: 0;
  color: #333;
  line-height: 1.2;
  transition: color 2s ease;
}

.nav {
  position: relative;
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.nav-item {
  margin-bottom: 0;
  transition: transform 0.3s ease;
}

.nav-item:hover {
  transform: translateY(-4px);
}

.nav-link {
  font-size: 2em;
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease;
}

.nav-link.active {
  font-size: 2.5em;
  font-weight: bold;
}

.external-link {
  text-decoration: none;
}

.navbar-github {
  margin-right: 0.1em;
  transition: color 0.3s ease;
}

.navbar-linkedin {
  margin-right: 0.1em;
  transition: color 0.3s ease;
}

.toggle-theme {
  background-color: #333;
  color: #f0f0f0;
  border: none;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.dark .toggle-theme {
  background-color: #f0f0f0;
  color: #333;
}

.toggle-theme:focus,
.toggle-theme:active {
  outline: none;
}

.dark .toggle-theme:focus,
.dark .toggle-theme:active {
  outline: none;
}


@media only screen and (max-width: 600px) {
  .name {
    font-size: 2.5rem;
  }
  
  .nav {
    flex-direction: column;
  }
  
  .nav-item {
    margin-bottom: 0.5rem;
  }
  
  .nav-link {
    font-size: 1.5rem;
  }

  .nav-link.active {
    font-size: 2rem;
  }
  
  .toggle-theme {
    font-size: 1.2rem;
    padding: 0.4rem 0.8rem;
    border-radius: 4px;
  }
  
  .about-container p {
    font-size: 1rem;
  }
  
  .projects-list h3 {
    font-size: 1.2rem;
  }
  
  .title-container span {
    font-size: 1rem;
  }
  
  .disclaimer {
    font-size: 0.8rem;
  }
}
