.about-container {

}

h2 {
  margin-bottom: 1rem;
}

p {
  font-size: 1.5rem;
}

