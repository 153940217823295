/* Projects.css */

.projects-container {
  font-size: 1.3rem;
}

h2 {
  margin-bottom: 1rem;
}

.projects-list ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.projects-list h3 {
  font-size: 1.5rem;
  color: #333;
  transition: all 0.5s ease;
}

.dark .projects-list h3 {
  color: #f0f0f0;
}

.projects-list li {
  background-color: #f8f8f8;
  padding: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.dark .projects-list li {
  background-color: #444;
  color: #f0f0f0;
}

.projects-list li a {
  text-decoration: none;
}

.projects-list li:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
  transition: all 0.5s ease; /* added transition here */
}

.item-container {
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title-container {
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0 0.5em;
  font-size: 1.2em;
}

.title-container a {
  text-decoration: none;
}

.tools-container {
  color: #2c5ba0;
}

.dark .tools-container {
  color: #BED6FA;
}



.dark .title-container span {
  color: #f0f0f0;
}

.disclaimer {
  color: #a4622c;
  font-size: 0.8em;
  margin-top: 0.2em;
  padding: 0 0.5em;
}

.dark .disclaimer {
  color: #fbd5c5;
}
